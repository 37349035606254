<template>
  <div class="container df">
    <div class="twoMenubox">
      <div class="item" :class="current == idx ? 'active' : ''" @click="menuHandle(i, idx)" v-for="(i, idx) in menu" :key="idx">
        <img :src="current == idx ? i.icon : i.activeicon" alt="" />
        <div>{{ i.title }}</div>
      </div>
    </div>

    <div class="list">
      <list ref="liveList" v-if="current == 0" @add="add"></list>
      <add v-if="current == 1" :img_id="imgId" @showList="add"></add>
    </div>
  </div>
</template>
<script>
import list from "./components/list.vue";
import add from "./components/add.vue";
import { ipcApiRoute, specialIpcRoute } from "@/api/main";
export default {
  components: {
    list,
    add,
  },
  name: "shortVideo",
  data() {
    return {
      imgId: "",
      current: 0,
      liveId: null,
      showList: true,
      menu: [
        {
          icon: require("@/assets/img/secondMenu/sortList1.png"),
          activeicon: require("@/assets/img/secondMenu/sortList.png"),
          title: "数字人作品",
          pageName: "shortVideo",
          params: {},
        },
        {
          icon: require("@/assets/img/secondMenu/addSort1.png"),
          activeicon: require("@/assets/img/secondMenu/addSort.png"),
          title: "数字人创作",
          pageName: "video",
          params: {},
        },
      ],
    };
  },
  mounted() {
    // this.init();

    console.log(111, this.$route);
    if (this.$route.params.imageId && this.$route.params.imageId > 0) {
      this.current = 1;
      this.imgId = this.$route.params.imageId;
    }
  },
  methods: {
    init() {
      this.$ipc.invoke(ipcApiRoute.openSoftware, "app.exe").then((r) => {
        console.log("resourcePath:", r);
      });
    },
    menuHandle(e, idx) {
      this.current = e ? idx : 0;
      const linkInfo = this.menu[this.current];
    },
    select(e) {
      this.active = e;
    },
    add(id) {
      this.liveId = id + "";
      this.showList = !this.showList;
      if (this.showList) {
        console.log(123);
        this.$refs.liveList.getlive();
      }
    },
  },
};
</script>
<style lang="less" scoped>
.twoMenubox {
  width: 12%;
  background: #fff;
  // border-right: 1px solid #eef0f2;
  .item {
    font-size: 16px !important;
  }
}
.list {
  width: 88%;
}
</style>
